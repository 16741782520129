import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Article, { ArticleContainer } from '../Article';

import { ArticleInterface } from '../../shared/Interfaces';

const query = graphql`
query HomeFeatured {
    allContentfulVest(
        limit: 3, 
        skip: 2, 
        filter: {kategorija: {nin: ["Ishrana", "Servisne Informacije", "Saveti i Preporuke", "Kultura", "Zdravstveno Osiguranje", "Socijalna Zaštita"]}}
        sort: {fields: createdAt, order: DESC}
        ) {
      nodes {
        naslov
        kategorija
        slug
        updatedAt
        slika {
          gatsbyImageData(
                layout: CONSTRAINED
                width: 355, 
                aspectRatio: 1.5 
                resizingBehavior: THUMB
            )
        }
      }
    }
  }
`;

const Main = () => {

    const data = useStaticQuery(query);

    const posts: ArticleInterface[] = data.allContentfulVest.nodes;

    return (
        <ArticleContainer type="triple">
            {posts.map(post => <Article key={post.slug} {...post} type="triple" /> )}
        </ArticleContainer>
    )
};

export default Main;