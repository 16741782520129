import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';

import Main from '../components/Home/Main';
import Featured from '../components/Home/Featured';
import Column, { ColumnContainer } from '../components/Column';
import Seo from '../components/Seo';

import { ArticleInterface } from '../shared/Interfaces';

const query = graphql`
query HomeSaveti {
    savetiMain: allContentfulVest(
        limit: 1, 
        filter: {kategorija: {in: "Saveti i Preporuke"}}
        sort: {fields: createdAt, order: DESC}
        ) {
      nodes {
        naslov
        kategorija
        slug
        updatedAt
        slika {
          gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.5 
                width: 545
                resizingBehavior: THUMB
            )
        }
      }
    }
    savetiMore: allContentfulVest(
        limit: 3, 
        skip: 1, 
        filter: {kategorija: {in: "Saveti i Preporuke"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  width: 200
                  height: 180
                  resizingBehavior: THUMB
              )
          }
        }
    }
    kulturaMain: allContentfulVest(
        limit: 1, 
        filter: {kategorija: {in: "Kultura"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1.5 
                  width: 545
                  resizingBehavior: THUMB
              )
          }
        }
    }
    kulturaMore: allContentfulVest(
        limit: 3, 
        skip: 1, 
        filter: {kategorija: {in: "Kultura"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  width: 200
                  height: 180
                  resizingBehavior: THUMB
              )
          }
        }
    }
    zdravstvenoMain: allContentfulVest(
        limit: 1, 
        filter: {kategorija: {in: "Zdravstveno Osiguranje"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1.5 
                  width: 545
                  resizingBehavior: THUMB
              )
          }
        }
    }
    zdravstvenoMore: allContentfulVest(
        limit: 3, 
        skip: 1, 
        filter: {kategorija: {in: "Zdravstveno Osiguranje"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  width: 200
                  height: 180
                  resizingBehavior: THUMB
              )
          }
        }
    }
    socijalnoMain: allContentfulVest(
        limit: 1, 
        filter: {kategorija: {in: "Socijalna Zaštita"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1.5 
                  width: 545
                  resizingBehavior: THUMB
              )
          }
        }
    }
    socijalnoMore: allContentfulVest(
        limit: 3, 
        skip: 1, 
        filter: {kategorija: {in: "Socijalna Zaštita"}}
        sort: {fields: createdAt, order: DESC}
        ) {
        nodes {
          naslov
          kategorija
          slug
          updatedAt
          slika {
            gatsbyImageData(
                  layout: CONSTRAINED
                  width: 200
                  height: 180
                  resizingBehavior: THUMB
              )
          }
        }
    }
  }
`;

const index = () => {

    const data = useStaticQuery(query);

    const savetiMain: ArticleInterface[] = data.savetiMain.nodes;
    const savetiMore: ArticleInterface[] = data.savetiMore.nodes;
    const kulturaMain: ArticleInterface[] = data.kulturaMain.nodes;
    const kulturaMore: ArticleInterface[] = data.kulturaMore.nodes;
    const zdravstvenoMain: ArticleInterface[] = data.zdravstvenoMain.nodes;
    const zdravstvenoMore: ArticleInterface[] = data.zdravstvenoMore.nodes;
    const socijalnoMain: ArticleInterface[] = data.socijalnoMain.nodes;
    const socijalnoMore: ArticleInterface[] = data.socijalnoMore.nodes;

    return (
        <Layout>
            <Seo />
            <Main />
            <Featured />
            <ColumnContainer type="double">
                {savetiMain.length > 0 && <Column title="Saveti i preporuke" main={savetiMain[0]} more={savetiMore} />}
                {kulturaMain.length > 0 && <Column title="Kultura" main={savetiMain[0]} more={kulturaMore} />}
            </ColumnContainer>
            <ColumnContainer type="double">
                {zdravstvenoMain.length > 0 && <Column title="Zdravstveno Osiguranje" main={zdravstvenoMain[0]} more={zdravstvenoMore} />}
                {socijalnoMain.length > 0 && <Column title="Socijalna Zaštita" main={socijalnoMain[0]} more={socijalnoMore} />}
            </ColumnContainer>
        </Layout>
    );
};

export default index;