import React from 'react';

import Article from './Article';

import { ArticleInterface } from '../shared/Interfaces';

interface Props {
    title: string;
    main: ArticleInterface;
    more: ArticleInterface[];
}

const Column: React.FC<Props> = ({ title, main, more }) => {
    return (
        <section className="column">
            <div className="column__header">
                <h2 className="column__title">
                    {title}
                </h2>
            </div>
            <div className="column__content">
                <div className="column__main">
                    <Article {...main} type="triple" />
                </div>
                <div className="column__data">
                    {more.map(article => <Article key={article.slug} type="quadra" {...article} /> )}
                </div>
            </div>
        </section>
    );
};

interface ContainerProps {
    type: 'double' | 'triple';
}

export const ColumnContainer: React.FC<ContainerProps> = ({ children, type }) => <div className={`column__container column__container--${type}`}>{children}</div>

export default Column;